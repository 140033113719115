import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{attrs:{"dense":"","dark":"","flat":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.aplicacion.nombre || "Modificar aplicación"))]),_c(VSpacer),_c(VBtn,{attrs:{"fab":"","icon":""},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pt-4"},[_c(VRow,{attrs:{"dense":""}},[(_vm.vaAModificar('nombre'))?_c(VCol,[_c(VTextField,{attrs:{"label":"Nombre","outlined":"","dense":"","error-messages":_vm.errors.nombre},on:{"blur":function($event){return _vm.validarCampo('nombre', ['required'])},"input":function($event){return _vm.validarCampo('nombre', ['required'])}},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}})],1):_vm._e(),(_vm.vaAModificar('criterio'))?_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.criteriosDisponibles,"item-value":"_id","item-text":"nombre","label":"Criterio de evaluación","outlined":"","dense":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.nombre))]),(item.seccion)?_c(VListItemSubtitle,[_vm._v(_vm._s(item.seccion))]):_vm._e()],1)]}}],null,false,2291788965),model:{value:(_vm.criterio),callback:function ($$v) {_vm.criterio=$$v},expression:"criterio"}})],1):_vm._e(),(_vm.vaAModificar('intentos'))?_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.intentosDisponibles,"outlined":"","dense":"","label":"Máximo de intentos","error-messages":_vm.errors.intentos},on:{"blur":function($event){return _vm.validarCampo('intentos', ['numeric', 'between'])},"change":function($event){return _vm.validarCampo('intentos', ['numeric', 'between'])}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" intentos ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" intentos ")]}}],null,false,904492860),model:{value:(_vm.intentos),callback:function ($$v) {_vm.intentos=$$v},expression:"intentos"}})],1):_vm._e(),(_vm.vaAModificar('calificacion'))?_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.calificacionesDisponibles,"item-text":"texto","item-value":"valor","outlined":"","dense":"","label":"Modo de calificación","error-messages":_vm.errors.calificacion},on:{"blur":function($event){return _vm.validarCampo('calificacion', ['required'])},"change":function($event){return _vm.validarCampo('calificacion', ['required'])}},model:{value:(_vm.calificacion),callback:function ($$v) {_vm.calificacion=$$v},expression:"calificacion"}})],1):_vm._e(),(_vm.vaAModificar('inicio'))?_c(VCol,{attrs:{"cols":"12"}},[_c('span',[_vm._v("Inicio de la aplicación")]),_c(VRow,{staticClass:"pt-2",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"8"}},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Fecha","dense":"","readonly":"","error-messages":_vm.errors.fechaInicio},on:{"blur":function($event){return _vm.validarCampo('fechaInicio', ['required'])},"change":function($event){return _vm.validarCampo('fechaInicio', ['required'])}},model:{value:(_vm.fechaInicio),callback:function ($$v) {_vm.fechaInicio=$$v},expression:"fechaInicio"}},'v-text-field',attrs,false),on))]}}],null,false,2577287787),model:{value:(_vm.menuFechaInicio),callback:function ($$v) {_vm.menuFechaInicio=$$v},expression:"menuFechaInicio"}},[_c(VDatePicker,{attrs:{"locale":"es"},on:{"input":function($event){_vm.menuFechaInicio = false},"change":function($event){return _vm.validarCampo('fechaInicio', ['required'])}},model:{value:(_vm.fechaInicio),callback:function ($$v) {_vm.fechaInicio=$$v},expression:"fechaInicio"}})],1)],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VMenu,{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Hora","dense":"","readonly":"","error-messages":_vm.errors.horaInicio},on:{"blur":function($event){return _vm.validarCampo('horaInicio', ['required'])},"change":function($event){return _vm.validarCampo('horaInicio', ['required'])}},model:{value:(_vm.horaInicio),callback:function ($$v) {_vm.horaInicio=$$v},expression:"horaInicio"}},'v-text-field',attrs,false),on))]}}],null,false,2160452918),model:{value:(_vm.menuHoraInicio),callback:function ($$v) {_vm.menuHoraInicio=$$v},expression:"menuHoraInicio"}},[_c(VCard,[_c(VTimePicker,{attrs:{"format":"24hr"},on:{"change":function($event){return _vm.validarCampo('horaInicio', ['required'])}},model:{value:(_vm.horaInicio),callback:function ($$v) {_vm.horaInicio=$$v},expression:"horaInicio"}}),_c(VContainer,{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.menuHoraInicio = false}}},[_vm._v("Ok")])],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.vaAModificar('fin'))?_c(VCol,{attrs:{"cols":"12"}},[_c('span',[_vm._v("Fin de la aplicación")]),_c(VRow,{staticClass:"pt-2",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"8"}},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Fecha","dense":"","readonly":"","error-messages":_vm.errors.fechaFin},on:{"blur":function($event){return _vm.validarCampo('fechaFin', ['required'])},"change":function($event){return _vm.validarCampo('fechaFin', ['required'])}},model:{value:(_vm.fechaFin),callback:function ($$v) {_vm.fechaFin=$$v},expression:"fechaFin"}},'v-text-field',attrs,false),on))]}}],null,false,1032854123),model:{value:(_vm.menuFechaFin),callback:function ($$v) {_vm.menuFechaFin=$$v},expression:"menuFechaFin"}},[_c(VDatePicker,{attrs:{"locale":"es"},on:{"input":function($event){_vm.menuFechaFin = false},"change":function($event){return _vm.validarCampo('fechaFin', ['required'])}},model:{value:(_vm.fechaFin),callback:function ($$v) {_vm.fechaFin=$$v},expression:"fechaFin"}})],1)],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VMenu,{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Hora","dense":"","readonly":"","error-messages":_vm.errors.horaFin},on:{"blur":function($event){return _vm.validarCampo('horaFin', ['required'])},"change":function($event){return _vm.validarCampo('horaFin', ['required'])}},model:{value:(_vm.horaFin),callback:function ($$v) {_vm.horaFin=$$v},expression:"horaFin"}},'v-text-field',attrs,false),on))]}}],null,false,3649657878),model:{value:(_vm.menuHoraFin),callback:function ($$v) {_vm.menuHoraFin=$$v},expression:"menuHoraFin"}},[_c(VCard,[_c(VTimePicker,{attrs:{"format":"24hr"},on:{"change":function($event){return _vm.validarCampo('horaFin', ['required'])}},model:{value:(_vm.horaFin),callback:function ($$v) {_vm.horaFin=$$v},expression:"horaFin"}}),_c(VContainer,{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.menuHoraFin = false}}},[_vm._v("Ok")])],1)],1)],1)],1)],1)],1):_vm._e(),_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"color":"secondary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","small":"","disabled":!_vm.formularioValido,"loading":_vm.loading},on:{"click":function($event){return _vm.guardar()}}},[_vm._v("Guardar")])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }