<template>
  <v-dialog max-width="500" persistent v-model="mostrar">
    <v-card :loading="loading">
      <v-toolbar dense dark flat color="primary">
        <v-toolbar-title>{{
          aplicacion.nombre || "Modificar aplicación"
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn fab icon @click="$emit('cancelar')">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4">
        <v-row dense>
          <v-col v-if="vaAModificar('nombre')">
            <v-text-field
              v-model="nombre"
              label="Nombre"
              outlined
              dense
              :error-messages="errors.nombre"
              @blur="validarCampo('nombre', ['required'])"
              @input="validarCampo('nombre', ['required'])"
            ></v-text-field>
          </v-col>
          <v-col v-if="vaAModificar('criterio')" cols="12">
            <v-select
              v-model="criterio"
              :items="criteriosDisponibles"
              item-value="_id"
              item-text="nombre"
              label="Criterio de evaluación"
              outlined
              dense
              :loading="loading"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                  <v-list-item-subtitle v-if="item.seccion">{{
                    item.seccion
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template></v-select
            >
          </v-col>
          <v-col cols="12" v-if="vaAModificar('intentos')">
            <v-select
              v-model="intentos"
              :items="intentosDisponibles"
              outlined
              dense
              label="Máximo de intentos"
              :error-messages="errors.intentos"
              @blur="validarCampo('intentos', ['numeric', 'between'])"
              @change="validarCampo('intentos', ['numeric', 'between'])"
            >
              <template #selection="{ item }"> {{ item }} intentos </template>
              <template #item="{ item }"> {{ item }} intentos </template>
            </v-select>
          </v-col>
          <v-col cols="12" v-if="vaAModificar('calificacion')">
            <v-select
              v-model="calificacion"
              :items="calificacionesDisponibles"
              item-text="texto"
              item-value="valor"
              outlined
              dense
              label="Modo de calificación"
              :error-messages="errors.calificacion"
              @blur="validarCampo('calificacion', ['required'])"
              @change="validarCampo('calificacion', ['required'])"
            >
            </v-select>
          </v-col>
          <v-col cols="12" v-if="vaAModificar('inicio')">
            <span>Inicio de la aplicación</span>
            <v-row no-gutters class="pt-2">
              <v-col cols="8">
                <v-menu v-model="menuFechaInicio" offset-y>
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaInicio"
                      label="Fecha"
                      dense
                      readonly
                      :error-messages="errors.fechaInicio"
                      v-bind="attrs"
                      v-on="on"
                      @blur="validarCampo('fechaInicio', ['required'])"
                      @change="validarCampo('fechaInicio', ['required'])"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaInicio"
                    locale="es"
                    @input="menuFechaInicio = false"
                    @change="validarCampo('fechaInicio', ['required'])"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-menu
                  v-model="menuHoraInicio"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="horaInicio"
                      label="Hora"
                      dense
                      readonly
                      :error-messages="errors.horaInicio"
                      v-bind="attrs"
                      v-on="on"
                      @blur="validarCampo('horaInicio', ['required'])"
                      @change="validarCampo('horaInicio', ['required'])"
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-time-picker
                      v-model="horaInicio"
                      format="24hr"
                      @change="validarCampo('horaInicio', ['required'])"
                    ></v-time-picker>
                    <v-container class="d-flex justify-end">
                      <v-btn
                        small
                        text
                        color="primary"
                        @click="menuHoraInicio = false"
                        >Ok</v-btn
                      >
                    </v-container>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="vaAModificar('fin')">
            <span>Fin de la aplicación</span>
            <v-row no-gutters class="pt-2">
              <v-col cols="8">
                <v-menu v-model="menuFechaFin" offset-y>
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaFin"
                      label="Fecha"
                      dense
                      readonly
                      :error-messages="errors.fechaFin"
                      v-bind="attrs"
                      v-on="on"
                      @blur="validarCampo('fechaFin', ['required'])"
                      @change="validarCampo('fechaFin', ['required'])"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaFin"
                    locale="es"
                    @input="menuFechaFin = false"
                    @change="validarCampo('fechaFin', ['required'])"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-menu
                  v-model="menuHoraFin"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="horaFin"
                      label="Hora"
                      dense
                      readonly
                      :error-messages="errors.horaFin"
                      v-bind="attrs"
                      v-on="on"
                      @blur="validarCampo('horaFin', ['required'])"
                      @change="validarCampo('horaFin', ['required'])"
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-time-picker
                      v-model="horaFin"
                      format="24hr"
                      @change="validarCampo('horaFin', ['required'])"
                    ></v-time-picker>
                    <v-container class="d-flex justify-end">
                      <v-btn
                        small
                        text
                        color="primary"
                        @click="menuHoraFin = false"
                        >Ok</v-btn
                      >
                    </v-container>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <div class="d-flex" style="width: 100%">
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                outlined
                small
                class="mr-2"
                :disabled="loading"
                @click="$emit('cancelar')"
                >Cancelar</v-btn
              >
              <v-btn
                color="primary"
                small
                :disabled="!formularioValido"
                :loading="loading"
                @click="guardar()"
                >Guardar</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from "luxon";

import { actualizarAplicacion } from "./examenAplicacion.service";
import { obtenerEvaluacionCursoService } from "../encuadre/evaluacion/evaluacion.service";

import { validationMixin } from "vuelidate";
import { required, numeric, between } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    aplicacion: {
      type: Object,
      required: true,
    },
    aModificar: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    loading: false,
    nombre: "",
    criterio: "",
    intentos: 1,
    calificacion: "",
    fechaInicio: "",
    menuFechaInicio: false,
    horaInicio: "",
    menuHoraInicio: false,
    fechaFin: "",
    menuFechaFin: false,
    horaFin: "",
    menuHoraFin: false,
    intentosDisponibles: [1, 2, 3, 4, 5],
    calificacionesDisponibles: [
      {
        valor: "promedio",
        texto: "Promedio de intentos",
      },
      {
        valor: "masAlta",
        texto: "Intento con calificación más alta",
      },
    ],
    criteriosDisponibles: [],
    defaults: {
      nombre: "",
      intentos: 1,
      calificacion: "promedio",
      criterio: "",
      fechaInicio: "",
      fechaFin: "",
      horaInicio: "",
      horaFin: "",
    },
    errors: {
      nombre: [],
      intentos: [],
      calificacion: [],
      criterio: [],
      fechaInicio: [],
      fechaFin: [],
      horaInicio: [],
      horaFin: [],
    },
    mensajesValidaciones: {
      required: "Campo requerido.",
      numeric: "Cantidad no válida.",
      between: "Cantidad no válida.",
    },
  }),

  validations: {
    nombre: { required },
    intentos: { numeric, between: between(1, 5) },
    calificacion: { required },
    criterio: { required },
    fechaInicio: { required },
    fechaFin: { required },
    horaInicio: { required },
    horaFin: { required },
  },

  computed: {
    idCurso() {
      return this.$route.params.idCurso;
    },

    idModulo() {
      return this.$route.params.idModulo;
    },

    idAplicacion() {
      return this.$route.params.idAplicacion;
    },

    tipoCurso() {
      return this.$route.params.tipoCurso;
    },

    vaAModificar() {
      return (valorAModificar) =>
        !!this.aModificar.find((e) => e === valorAModificar);
    },

    tieneErrores() {
      return (campo) => !!this.errors[campo].length;
    },

    formularioValido() {
      if (this.tieneErrores("nombre")) return false;
      if (this.tieneErrores("intentos")) return false;
      if (this.tieneErrores("calificacion")) return false;
      if (this.tieneErrores("criterio")) return false;
      if (this.tieneErrores("fechaInicio")) return false;
      if (this.tieneErrores("fechaFin")) return false;
      if (this.tieneErrores("horaInicio")) return false;
      if (this.tieneErrores("horaFin")) return false;
      return true;
    },

    inicio() {
      if (this.fechaInicio && this.horaInicio)
        return new Date(`${this.fechaInicio} ${this.horaInicio}`);
      return null;
    },

    fin() {
      if (this.fechaFin && this.horaFin)
        return new Date(`${this.fechaFin} ${this.horaFin}`);
      return null;
    },

    inputData() {
      return {
        ...(this.vaAModificar("nombre") && { nombre: this.nombre }),
        ...(this.vaAModificar("intentos") && { intentos: this.intentos }),
        ...(this.vaAModificar("calificacion") && {
          calificacion: this.calificacion,
        }),
        ...(this.vaAModificar("criterio") && {
          criterio: this.criterio,
        }),
        ...(this.vaAModificar("inicio") && {
          inicio: this.inicio,
        }),
        ...(this.vaAModificar("fin") && {
          fin: this.fin,
        }),
      };
    },
  },

  mounted() {
    if (this.vaAModificar("criterio")) this.cargarEvaluacion();
    this.setActualData();
  },

  methods: {
    setActualData() {
      this.nombre = this.aplicacion.nombre || this.defaults.nombre;
      this.intentos = this.aplicacion.intentos || this.defaults.intentos;
      this.calificacion =
        this.aplicacion.calificacion || this.defaults.calificacion;
      this.criterio = this.aplicacion.criterio || this.defaults.criterio;
      if (this.aplicacion.inicio) {
        this.fechaInicio = DateTime.fromISO(this.aplicacion.inicio).toISODate();
        this.horaInicio = DateTime.fromISO(this.aplicacion.inicio).toLocaleString(DateTime.TIME_24_SIMPLE);
      }
      if (this.aplicacion.fin) {
        this.fechaFin = DateTime.fromISO(this.aplicacion.fin).toISODate();
        this.horaFin = DateTime.fromISO(this.aplicacion.fin).toLocaleString(DateTime.TIME_24_SIMPLE);
      }
    },

    validarFormulario() {
      if (this.vaAModificar("nombre"))
        this.validarCampo("nombre", ["required"]);
      if (this.vaAModificar("intentos"))
        this.validarCampo("intentos", ["numeric", "between"]);
      if (this.vaAModificar("calificacion"))
        this.validarCampo("calificacion", ["required"]);
      if (this.vaAModificar("criterio"))
        this.validarCampo("criterio", ["required"]);
      if (this.vaAModificar("inicio")) {
        this.validarCampo("fechaInicio", ["required"]);
        this.validarCampo("horaInicio", ["required"]);
      }
      if (this.vaAModificar("fin")) {
        this.validarCampo("fechaFin", ["required"]);
        this.validarCampo("horaFin", ["required"]);
      }
    },

    validarCampo(nombreCampo, validators = []) {
      this.$v[nombreCampo].$touch();
      this.errors[nombreCampo] = validators
        .map((validation) => {
          const resultado = !!this.$v[nombreCampo][validation]
            ? null
            : this.mensajesValidaciones[validation];

          return resultado;
        })
        .filter((e) => e !== null);
    },

    async guardar() {
      this.validarFormulario();
      if (!this.formularioValido) return;

      this.loading = true;
      try {
        const serverResponse = await actualizarAplicacion({
          idCurso: this.idCurso,
          idModulo: this.idModulo,
          idAplicacion: this.idAplicacion,
          data: this.inputData,
        });
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("aplicacionGuardada", serverResponse.aplicacion);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async cargarEvaluacion() {
      try {
        this.criteriosDisponibles = [
          { _id: null, nombre: "Ninguno", seccion: "" },
        ];
        this.loading = true;
        const serverResponse = await obtenerEvaluacionCursoService(
          this.tipoCurso,
          this.idCurso
        );
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          serverResponse.secciones.forEach((seccion) => {
            const criteriosValidos = seccion.criterios.filter(
              (x) => !x.asistencias.requerido
            );
            const criterios = criteriosValidos.map((criterio) => {
              return {
                _id: criterio._id,
                nombre: criterio.nombre,
                seccion: seccion.nombre,
              };
            });
            this.criteriosDisponibles.push(...criterios);
          });
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
